import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Header from './Components/Header';
import VisitorInfo from './Info';

const apiUrl = process.env.REACT_APP_API_URL;

const Participantes = () => {
    const [data, setData] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}user/userlist`);
                const result = await response.json();
                setData(result.users || []); // Evita errores si `users` no está definido
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    // Extraer opciones únicas de colegios y ordenarlas alfabéticamente
    const schoolOptions = [...new Set(data.map(item => item.use_txt_school))].sort();

    const handleSchoolFilterChange = (event) => {
        setSelectedSchool(event.target.value);
    };

    const filteredData = selectedSchool
        ? data.filter(item => item.use_txt_school === selectedSchool)
        : data;

    const columns = [
        { name: 'N°', cell: (row, index) => index + 1, sortable: false, width: '60px' },
        { name: 'DNI', selector: row => row.use_txt_dni, sortable: true, width: '150px' },
        { name: 'Colegio', selector: row => row.use_txt_school, sortable: true, width: '380px' },
        { name: 'Se unió en:', selector: row => row.use_txt_datecreation, sortable: true, width: '150px' },
    ];

    return (
        <div>
            <Header />
            <VisitorInfo page="PARTICIPANTES" />
            <section className='p-2'>
                <h1 style={{ fontSize: 25 }}><b>Participantes - Concurso</b></h1>

                <div className='p-2 mt-3'>
                    <label htmlFor="schoolSelect"><b>Filtrar por Colegio:</b></label>
                    <select
                        id="schoolSelect"
                        value={selectedSchool}
                        onChange={handleSchoolFilterChange}
                    >
                        <option value="">Mostrar Todos</option>
                        {schoolOptions.map(school => (
                            <option key={school} value={school}>{school}</option>
                        ))}
                    </select>
                </div>

                <div className='p-2 mt-3'>
                    <p><b>Resultados encontrados:</b> {filteredData.length}</p>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                />
            </section>
        </div>
    );
};

export default Participantes;
