import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Header from './Components/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import VisitorInfo from './Info';

const apiUrl = process.env.REACT_APP_API_URL;

const Arbolesregistrados = () => {
    const [trees, setTrees] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTrees = async () => {
            try {
                const response = await axios.get(`${apiUrl}trees`);
                setTrees(response.data.trees || []); // Asegura que trees sea un array válido
            } catch (error) {
                console.error("Error fetching trees", error);
            }
        };

        fetchTrees();
    }, []);

    const columns = [
        { name: 'Código de árbol', selector: row => row.reg_txt_identifier, sortable: true },
        { name: 'Nombre común', selector: row => row.reg_txt_commonnames, sortable: true  },
        { name: 'Nombre científico', selector: row => row.reg_txt_scientificname, sortable: true },
        { name: 'Usos de la especie', selector: row => row.reg_txt_applications, sortable: true, width: '400px' },
        {
            name: 'Acción',
            sortable: false,
            cell: row => (
                <a 
                    href={`/ficha-arbol/${row.reg_int_id}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <button
                        className="btn btn-success"
                        style={{ 
                            padding: '10px 20px',
                            borderRadius: '8px',
                        }}
                    >
                        Ver Ficha
                    </button>
                </a>
            ),
        },
    ];
    
    return (
        <>
            <Header />
            <VisitorInfo page="ARBOLES REGISTRADOS" />
            <section className='mt-5'>
                <h4>Total de árboles registrados: {trees.length}</h4>
                <div>
                    <DataTable
                        columns={columns}
                        data={trees}
                        pagination
                        highlightOnHover
                    />
                </div>
            </section>
        </>
    );
};

export default Arbolesregistrados;
